import { Injectable, inject } from '@angular/core';
import { JasminEvents } from './jasmin-events.service';
import { AppInitializerCustomBaseService } from '@polygon/services';

@Injectable()
export class JasminAppInitializer extends AppInitializerCustomBaseService {
	private readonly _jasminEvents = inject(JasminEvents);

	override async initialize(): Promise<void> {
		this._jasminEvents.init();
	}
}
