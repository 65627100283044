import { Injectable, inject } from '@angular/core';
import { EnvironmentSettings, SettingsService } from '@polygon/services';
import { IApplication, IApplicationsProvider } from '@polygon/shell';

@Injectable()
export class EnvironmentApplicationsProviderService implements IApplicationsProvider {
	private applications: IApplication[];

	protected readonly settingsService = inject(SettingsService);
	private readonly environmentSettings = inject(EnvironmentSettings);

	async getApplications(): Promise<IApplication[]> {
		if (!this.applications) {
			this.applications = await this.getEnvironmentApps();
		}

		return this.applications;
	}

	private async getDefaultApplications(): Promise<IApplication[]> {
		return await this.settingsService.getApplicationsConfiguration();
	}

	private async getEnvironmentApps(): Promise<IApplication[]> {
		let apps: IApplication[] = await this.getDefaultApplications();

		// ESS
		const essUrl = this.environmentSettings['essServiceUrl'];
		try {
			if (essUrl !== undefined && essUrl.length) {
				const essIndex = apps.findIndex((app) => app.title == 'clientApp.components.RES_Application_ESS_Title');

				if (essIndex === -1) {
					apps.push({
						title: 'clientApp.components.RES_Application_ESS_Title',
						description: 'clientApp.components.RES_Application_ESS_Description',
						url: essUrl,
						icon: 'ti-ux-official-reports-outline'
					});
				} else {
					apps[essIndex].url = essUrl;
				}
			}
		} catch (error) {
			console.error(error);
		}

		return apps;
	}
}
