import { Component, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BroadcastService, DomService } from '@polygon/services';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
        RouterModule
    ]
})
export class AppComponent {

	constructor(
		private broadcastService: BroadcastService,
		private domService: DomService
	) {
		this.broadcastService.subscribe('clientAppReady', () => {
			if (this.domService.document.getElementsByClassName('main-loader')?.length > 0) {
				document.body.classList.remove('is-app-initializing');
				this.domService.document.getElementsByClassName('main-loader')[0].remove();
			}
		});
	}
}
