import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { providePrimaveraLocalization } from '@polygon/localization';
import {
    AppInitializer,
    DEPLOY_URL_PROVIDER,
    EnvironmentSettings,
    PrimaveraServicesModule,
    addIgnoreCaseUrlsStrategy
} from '@polygon/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { provideToastr } from 'ngx-toastr';
import { customMainServices } from '../custom-code/custom.services';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { addElevationFwShellProviders } from "@polygon/shell";

const deploy = DEPLOY_URL_PROVIDER;

fetch(`${deploy.useValue}assets/environment.json`, {
    method: 'GET',
    headers: {
        'Cache-Control': 'no-cache'
    }
})
    .then((response) => response.json())
    .then((config: EnvironmentSettings) => {
        if (config.production) {
            enableProdMode();
        }

        bootstrapApplication(AppComponent, {
            providers: [
                addIgnoreCaseUrlsStrategy,
                importProvidersFrom(PrimaveraServicesModule),
                {
                    provide: APP_INITIALIZER,
                    useFactory: (appInitializer: AppInitializer) => async () => await appInitializer.init(),
                    deps: [AppInitializer],
                    multi: true
                },
                DEPLOY_URL_PROVIDER,
                {
                    provide: EnvironmentSettings,
                    useValue: config
                },
                ...customMainServices,
                provideAnimations(),
                provideHttpClient(withInterceptorsFromDi()),
                provideRouter(appRoutes),
                provideServiceWorker(`${deploy.useValue}ngsw-worker.js`, {
                    enabled: !isDevMode(),
                    registrationStrategy: 'registerWhenStable:30000'
                }),
                provideToastr(),
                BsModalService,
                providePrimaveraLocalization(),
                addElevationFwShellProviders
            ]
        }).catch((err) => console.error(err));
    });
