import { Route, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import businessAppRoutes from './business.routes.json';
import dashboardAppRoutes from './dashboards.routes.json';
import formsAppRoutes from './forms.routes.json';
import listsAppRoutes from './lists.routes.json';

export const formsAppRouteMatcher = (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {

    if (!formsAppRoutes && formsAppRoutes.length > 0) {
        return null;
    }

    // get target module route

    const targetRoute = segments.slice(-3).flatMap(x => x.path).join('/');

    // special cases

    const isForms =
        (targetRoute.startsWith('customentities') && (targetRoute.endsWith('create') || targetRoute.endsWith('edit')))
        || targetRoute === 'reporting/templates/edittemplate'
        || targetRoute.endsWith('/redirect');

    if (formsAppRoutes.includes(targetRoute) || isForms) {
        return {
            consumed: []
        };
    } else {
        return null;
    }
};

export const listsAppRouteMatcher = (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {

    if (!listsAppRoutes && listsAppRoutes.length > 0) {
        return null;
    }

    // get target module route

    const targetRoute = segments.slice(-3).flatMap(x => x.path).join('/');

    // route is on json file or is generic extensibility list route

    if (listsAppRoutes.includes(targetRoute)
        || (targetRoute.startsWith('customentities') && targetRoute.endsWith('list'))) {
        return {
            consumed: []
        };
    } else {
        return null;
    }
};

export const dashboardAppRouteMatcher = (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {

    if (!dashboardAppRoutes && dashboardAppRoutes.length > 0) {
        return null;
    }

    // get target module route
    const targetRoute = segments.slice(-3).flatMap(x => x.path).join('/');

    if (dashboardAppRoutes.includes(targetRoute)) {
        return {
            consumed: []
        };
    } else {
        return null;
    }
};

export const businessAppRouteMatcher = (segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null => {

    if (!businessAppRoutes && businessAppRoutes.length > 0) {
        return null;
    }

    // get target module route
    const targetRoute = segments.slice(-3).flatMap(x => x.path).join('/');

    if (businessAppRoutes.includes(targetRoute)) {
        return {
            consumed: []
        };
    } else {
        return null;
    }
};

