// Angular and third party
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/internal/operators/filter';

// @Primavera
import {
  DraftModelService,
  ExceptionHandlerService,
  MessageInfo,
  MessageType,
  MessagesService,
} from '@polygon/components';
import { I18nService } from '@polygon/localization';
import {
  AmsService,
  EventService,
  FormEvent,
  FormModelService,
  OutletEnum,
  RouteHelperService,
  ShellStateService,
} from '@polygon/services';

@Injectable({
  providedIn: 'root',
})
export class JasminEvents implements OnDestroy {
  private eventSubscriberLoginDRNESettings: Subscription;
  private eventSubscriberCommunicateSeriesSettings: Subscription;

  private i18nService = inject(I18nService);
  private messagesService = inject(MessagesService);
  private formModelService = inject(FormModelService);
  private shellStateService = inject(ShellStateService);
  private eventService = inject(EventService);
  private http = inject(HttpClient);
  private router = inject(Router);
  private amsService = inject(AmsService);
  private draftModelService = inject(DraftModelService);
  private routeHelperService = inject(RouteHelperService);
  private exceptionHandlerService = inject(ExceptionHandlerService);

  init() {
    this.eventSubscriberLoginDRNESettings = this.eventService
      .getOnEvent()
      .pipe(filter((formEvent: FormEvent) => formEvent.key === 'loginDNRE'))
      .subscribe((formEvent: FormEvent) => {
        this.loginServer(formEvent);
      });

    this.eventSubscriberCommunicateSeriesSettings = this.eventService
      .getOnEvent()
      .pipe(
        filter(
          (formEvent: FormEvent) => formEvent.key === 'communicateOneSeries',
        ),
      )
      .subscribe((formEvent: FormEvent) => {
        this.communicateSeries(formEvent);
      });
  }

  ngOnDestroy(): void {
    this.eventSubscriberLoginDRNESettings.unsubscribe();
    this.eventSubscriberCommunicateSeriesSettings.unsubscribe();
  }

  private communicateSeries(formEvent: FormEvent) {
    const model = formEvent.model;
    const endpoint = '/app/taxesCore/seriesCommunications/CommunicateOneSeries';
    this.setLoadingDots(true, model, formEvent);

    const body = {
      companyKey: model.company,
      seriesKey: model.serie,
      documentTypeKey: model.documentType,
      fiscalDocumentTypeKey: model.fiscalDocumentType,
    };

    const requestOptions: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    this.http
      .post(endpoint, JSON.stringify(body), requestOptions)
      .toPromise()
      .then((data) => {
        if ((data as any).responseCode === '0') {
          this.messagesService.show(
            new MessageInfo(
              (data as any).responseDescription,
              null,
              MessageType.Success,
            ),
          );
          const draftModel = this.draftModelService.build(
            formEvent.operationInfo.module,
            formEvent.operationInfo.entity,
          );

          Promise.all([
            draftModel.update(model.id, 'isSeriesCommunicated', 'true'),
            draftModel.update(
              model.id,
              'serie',
              `${model.documentType}${model.serie}`,
            ),
          ])
            .then((_) => {
              this.refreshPageCommunication();
              this.setLoadingDots(false, model, formEvent);
            })
            .catch((error) => {
              this.exceptionHandlerService.showError(error);
              this.setLoadingDots(false, model, formEvent);
            });
        } else {
          this.messagesService.show(
            new MessageInfo(
              (data as any).responseDescription,
              null,
              MessageType.Error,
            ),
          );
          this.refreshPageCommunication();
          this.setLoadingDots(false, model, formEvent);
        }
      })
      .catch((error) => {
        this.exceptionHandlerService.showError(error);
        this.refreshPageCommunication();
        this.setLoadingDots(false, model, formEvent);
      });
  }

  private loginServer(formEvent: FormEvent) {
    const model = formEvent.model;
    const endpoint = `${formEvent.operationInfo.service}/${formEvent.operationInfo.module}/${formEvent.operationInfo.operation}`;
    this.setLoading(true, model);
    const requestOptions: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    this.http
      .post(endpoint, JSON.stringify(model.company), requestOptions)
      .subscribe((data) => {
        let windowRef;

        const callback = (message) => {
          // TODO Review this condition - required to prevent handling of another events
          if (message.data.toString().startsWith('#?state=')) {
            window.removeEventListener('message', callback);
            windowRef.open('', '_parent', '');
            windowRef.close();
            this.sendCode(message, model, endpoint);
          }
        };

        const closeCallback = () => {
          this.setLoading(false, model);
        };

        const openDialog = (uri, name, options, _closeCallback) => {
          windowRef = window.open(uri, name, options);
          const interval = window.setInterval(() => {
            try {
              if (windowRef == null || windowRef.closed) {
                window.clearInterval(interval);
                _closeCallback(windowRef);
              }
            } catch (e) {}
          }, 1000);

          return windowRef;
        };

        windowRef = openDialog(
          data,
          '',
          this.calculatePopupFeatures(),
          closeCallback,
        );
        window.addEventListener('message', callback);
      });
  }

  private sendCode(hash, model, endpoint) {
    const url = `${endpoint}/callback${hash.data.replace('#', '')}`;
    const requestOptions: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    this.http
      .post(url, JSON.stringify(model.company), requestOptions)
      .toPromise()
      .then((o) => {
        this.messagesService.show(
          new MessageInfo(
            this.i18nService.get(
              'jasmin.components.RES_DNRE_ElectronicCommunication_Configured',
            ),
            null,
            MessageType.Success,
          ),
        );

        this.refreshPage();
        this.setLoading(false, model);
      })
      .catch((error) => {
        this.exceptionHandlerService.showError(error);
        this.setLoading(false, model);
      });
  }

  private calculatePopupFeatures() {
    // Specify an static height and width and calculate centered position
    const height = 470;
    const width = 500;
    const left = window.screenLeft + (window.outerWidth - width) / 2;
    const top = window.screenTop + (window.outerHeight - height) / 2;
    return `location=no,toolbar=no,width=${width},height=${height},top=${top},left=${left}`;
  }

  private setLoadingDots(value: boolean, model: any, formEvent: FormEvent) {
    formEvent.formState.loading = value;
    this.setLoading(value, model);
  }

  private setLoading(value: boolean, model: any) {
    this.shellStateService.setLoadingState(value);
    this.formModelService.onModelChange(model);
  }

  private refreshPage() {
    const currentRouteInfo = this.routeHelperService.getRouteInfo();
    const params = {
      nocache: new Date().getTime(),
    };

    this.routeHelperService.navigateUsingRouteInfo(
      this.amsService.account,
      this.amsService.organization,
      currentRouteInfo,
      params,
      OutletEnum.Primary,
      true,
      false,
    );
  }

  private refreshPageCommunication() {
    const url: string = window.location.pathname;
    const parametersFromUri: { [key: string]: any } =
      this.routeHelperService.getParametersFromUri();
    parametersFromUri['nocache'] = new Date().getTime();
    this.router
      .navigate([url], { replaceUrl: false, queryParams: parametersFromUri })
      .then((_) => {
        delete parametersFromUri['nocache'];
        this.router.navigate([url], {
          replaceUrl: false,
          queryParams: parametersFromUri,
        });
      });
  }
}
