import { Provider } from '@angular/core';
import { AppInitializerCustomService } from '@polygon/services';
import { APPLICATIONS_PROVIDER_TOKEN } from '@polygon/shell';
import { EnvironmentApplicationsProviderService } from './services/environment-applications-provider.service';
import { JasminAppInitializer } from './services/jasmin-app-initializer.service';

export const customServices: Array<Provider> = [];

export const customMainServices: Array<Provider> = [
	{
		provide: APPLICATIONS_PROVIDER_TOKEN,
		useClass: EnvironmentApplicationsProviderService
	},
	{
		provide: AppInitializerCustomService,
		useClass: JasminAppInitializer
	}
];
